/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from "@angular/core";
import { FormArray, FormBuilder } from "@angular/forms";
import _ from 'underscore';
import { MatChipList } from '@angular/material/chips';

const regexp = new RegExp(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);

@Injectable({
  providedIn: 'root'
})
export class PasteChipsItemsService {
  constructor() {}

  errorMsg = '';

  pastedElements(values: string [], emailsControls: FormArray, formBuilder: FormBuilder, list: MatChipList): string {
    this.errorMsg = '';
    let valuePasteReturn: string [] = [];
    let trimmedValues = _.map(values, (email) => {return email.trim()});
    //check for malformed mails
    const isEmailAtPaste = _.find(trimmedValues, (email) => {
      return !regexp.test(email.trim());
    });

    if (isEmailAtPaste !== undefined) {
      valuePasteReturn = _.filter(trimmedValues, (email) => {
        return !regexp.test(email.trim());
      });
      //remove malformed MatChipList
      trimmedValues = _.filter(trimmedValues, (email) => {
        return regexp.test(email.trim());
      });
    }

    //get All emails in control
    trimmedValues = trimmedValues.concat(_.pluck(emailsControls.controls, 'value'));

    //check for duplicates
    const findDuplicates = _.unique(_.filter(trimmedValues, (email) => _.filter(trimmedValues, (item: string)=> item.trim() === email.trim()).length > 1));

    //remove duplicates
    trimmedValues = _.uniq(trimmedValues, false);
    emailsControls.clear();

    if (trimmedValues !== undefined) {
      _.forEach(trimmedValues, (value) => {
        if (value.trim()) {
          emailsControls.push(formBuilder.control(value.trim()));
        }
      });
    }

    if (isEmailAtPaste !== undefined && findDuplicates.length > 0) {
      this.errorMsg = 'There is an invalid email in the list you just paste. Please check that an email must be: contoso@domain or for duplicated emails';
    } else if (isEmailAtPaste !== undefined && findDuplicates.length === 0) {
      this.errorMsg = 'There is an invalid email in the list you just paste. Please check that an email must be: contoso@domain';
    } else if (findDuplicates.length > 0 && isEmailAtPaste === undefined) {
      this.errorMsg = 'There is an invalid email in the list you just paste. Please check for duplicated emails';
    }
    list.errorState = emailsControls.controls.length > 0 ? false : true;
    return this.errorMsg;
  }
}
