/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    private matDialog: MatDialog
  ){ }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const origin = sessionStorage.getItem('origin');
      const tk = route.queryParams?.token;
      const provider = route.queryParams?.provider;
      if(provider){
        sessionStorage.setItem('provider', provider);
      }    
      if(!this.authService.isLoggedIn) {       
        const url = state.url !== '/' ? state.url : '/dashboard' ;
        const urlWithoutQueryParams = url?.split('?')?.length > 0 ? url.split('?')[0]: url;
        try {
          const classId = route.params?.classId;
          const districtId = route.queryParams?.districtId;
          const className = route.queryParams?.className;
          const schoolId = route.queryParams?.schoolId;
          const redirectUrl = route.queryParams?.redirectUrl;
          if(url !== '/dashboard' && classId && districtId) {
            sessionStorage.setItem('currentClass', JSON.stringify({classId: classId, districtId: districtId, schoolId: schoolId, className: className, status: 'Creating VM instance'}));
            sessionStorage.setItem('origin', 'lti');
            sessionStorage.setItem('redirectUrl', redirectUrl);
            sessionStorage.setItem('lastDistrict', districtId );
          }
        }catch(e) {
          console.log(e);
        }
        if(tk) {
          return this.authService.authWithCustomTk(tk, urlWithoutQueryParams);
        }else {
          void this.router.navigate(['/login']);
        }

      } else {
        if ( (this.authService.isLoggedIn !== true  && this.router.url !== "/trial") || origin === 'admin' ) {
          void this.router.navigate(['/login']);
          this.matDialog.closeAll();
          if(origin === 'admin'){ sessionStorage.clear(); }
        } else {
          const isAnonymous = this.authService.getUserFromStorage()?.email?.indexOf('demo.itopia.com') > -1 || false;
          if(isAnonymous){
              this.authService.signOut(true);
              window.location.href = "http://itopia.com/students/";
              return false;
          }
        }
        return true;
      }
      return false;
  }
}
