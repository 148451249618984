export enum SessionStatus {
  Starting_VM = 'Starting Virtual Machine',
  Retry_Resources = `Compute resources unavailable. Retrying...`,
  Retry_GPU = `GPU resoruces unavailable. Retrying...`,
  No_Available = `No resources available. Try again later.`,
  Preparing = `Preparing your session`,
  Running = 'Session ready',
  NotRunning = 'Not running',
  ShuttingDown = 'Shutting Down'
}
