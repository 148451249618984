import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookieTkService {
  private apiUrl = "https://" + environment.apiURL+ "/api/login"; // Ajusta la URL según sea necesario

  constructor(private http: HttpClient) { }

  setCookie(token:string): Observable<any> {
    return this.http.post(this.apiUrl, { token }, { withCredentials: true });
  }
}