import { NgModule } from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {split, ApolloClientOptions, InMemoryCache, ApolloLink} from '@apollo/client/core';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpErrorResponse } from '@angular/common/http';
import { timer } from 'rxjs';
import _ from 'underscore';
import { CookieService } from 'ng2-cookies';

import { AuthService, AlertService } from './shared/services';
import { environment } from 'src/environments/environment';
import { AuthCookieService } from './shared/services/cookies.service';

const path = `${environment.apiURL}/graphql`;
const uri =  `https://${path}`;

let token: string | null;
@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, authService: AuthService, alertService: AlertService, cookieService: AuthCookieService): ApolloClientOptions<any> {
        const basic = setContext((operation, context) => ({
          headers: {
            Authorization: `Bearer `,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, X-forwarded-for, Content-Type, Accept, Authorization',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,PATCH,DELETE',
            'Content-Type': 'application/json;charset=utf-8',
            responseType: 'blob' as 'json',
          }
        }));
        const auth = setContext((operation, context) => {
         const token = cookieService.getAuth('token');
         if (token === null) {
            return {};
          } else {
            return {
              headers: {
                Authorization: `Bearer ${token}`,
                'ngsw-bypass':'true',
              }
            };
          }
        });
        // Create a WebSocket link:
        const ws = new WebSocketLink({
          uri: `wss://${path}`,
          options: {
            timeout: 100000,
            reconnect: true,
            lazy: true,
            connectionParams: () => {
              return {
                token: `Bearer ${token}`,
                'ngsw-bypass':'true',
              };
            },
          },
        });
        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
              {
                const msg =  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
                alertService.error(msg);
              }
            );
          }
          if (networkError) {
            if (networkError instanceof HttpErrorResponse){
              if (networkError.status === 401) {
                  authService.signOut();
              }
              const errorMsgs = _.pluck(networkError.error.errors, 'message');
              errorMsgs.forEach((msg: string) => {
                const timerObs = timer(2).subscribe(() => {
                  alertService.error(msg);
                  timerObs.unsubscribe();
                });
              });
            } else {
              console.log(`[Network error]: ${networkError}`);
            }
          }
        });
        const http = ApolloLink.from([basic, auth, errorLink, httpLink.create({ uri, withCredentials: true })]);
        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
          // split based on operation type
          ({query}) => {
            const operationDefinitionNode = getMainDefinition(query);
            return (
              operationDefinitionNode.kind === 'OperationDefinition' && operationDefinitionNode.operation === 'subscription'
            );
          },
          ws,
          http
        );
        return {
          link, cache: new InMemoryCache(),
        };
      },
      deps: [HttpLink, AuthService, AlertService, AuthCookieService],
    },
    CookieService
  ],
})
export class GraphQLModule {}
