import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/services/auth.guard';
import { InstantSessionGuard } from './instant-session/instant-session.guard';
import { Token } from '@angular/compiler';
import { TokenGuard } from './shared/services/token.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [AuthGuard, TokenGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  {
    path: 'dashboard/start-instance/:classId',
    canActivate: [ AuthGuard, TokenGuard,],
    loadChildren: () => import('./start-instance/start-instance.module').then(m => m.StartInstanceModule),
  },
  {
    path: 'v2/started/:vmInstance',
    canActivate: [TokenGuard],
    loadChildren: () =>
      import('./istreamer/istreamer.module').then(m => m.IstreamerModule)
  },
  {
    path: 'v2/demo/:vmInstance',
    canActivate: [TokenGuard],
    loadChildren: () =>
    import('./istreamer/istreamer.module').then(m => m.IstreamerModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./trial-wizzard/trial-wizzard.module').then(m => m.TrialWizzardModule)
  },
  {
    path: 'trial',
    loadChildren: () => import('./trial/trial.module').then(m => m.TrialModule)
  },
  {
    path: 'trial/:uid',
    canActivate: [TokenGuard],
    loadChildren: () => import('./trial/trial.module').then(m => m.TrialModule)
  },
  {
    path: 'trial/:uid/finish',
    canActivate: [TokenGuard],
    loadChildren: () => import('./trial/trial.module').then(m => m.TrialModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error-code/error-code.module').then(m => m.ErrorCodeModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info-code/info-code.module').then(m => m.InfoCodeModule)
  },
  {
    path: 'demo',
    loadChildren: () =>
      import('./instant-session/instant-session.module').then(
        m => m.InstantSessionModule
      ),
      canActivate: [InstantSessionGuard]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
