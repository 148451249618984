import { AuthService } from './shared/services/auth.service';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule
} from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { environment } from 'src/environments/environment';
import {
  ServiceWorkerModule,
  SwRegistrationOptions
} from '@angular/service-worker';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ModalModule } from './shared/components/modal/modal.module';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { FullscreenFriendlyOverlayContainer } from './shared/services/fullscreen-friendly-overlay-container.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgxIstreamerModule } from '@itopia/ngx-istreamer';
import { NgxIstreamerDiagnosticsModule } from '@itopia/ngx-istreamer-diagnostics';
import { ChunkhandlerService } from './shared/services';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    NgxIstreamerModule,
    NgxIstreamerDiagnosticsModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    MatCarouselModule.forRoot(),
    ModalModule
  ],
  providers: [
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: { separatorKeyCodes: [COMMA, ENTER] }
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'check', color: 'primary' }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: false,
        disableClose: true,
        closeOnNavigation: true
      }
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production })
    },
    { provide: OverlayContainer, useClass: FullscreenFriendlyOverlayContainer },
    {provide: ErrorHandler, useClass: ChunkhandlerService},
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
