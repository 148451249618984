import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ProblemsInterceptorService {

  constructor(private authService: AuthService, private alertService: AlertService) {}

  intercept(problem: any): void {
    if (problem) {
      const typeName = problem.__typename;
      switch(typeName) {
        case 'AuthenticationRequiredProblem':
            void this.authService.signOut();
            break;
        default:
            if (problem.message) {
              this.alertService.error(problem.message, 'Validation error');
            }
      }

    }
  }
}
