import { Component, HostListener } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ThememodeService } from 'src/app/shared/services/thememode.service';
import { defaultUrlAssets, iconsSvg } from './shared/consts';

const excludedStorageKeys = ["tabId"];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'k12-user';
  constructor(private thememodeService: ThememodeService,
    private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
      // Load Color Scheme
      this.thememodeService.load();

      const urlAssets = defaultUrlAssets;
      iconsSvg.forEach(icon => {
        this.matIconRegistry.addSvgIcon(
         icon.icon,
         this.domSanitizer.bypassSecurityTrustResourceUrl(urlAssets + icon.url)
        );
      });

      if (sessionStorage.length === 0 || localStorage.length === 0) {
        // Ask other tabs for session storage
        localStorage.setItem('getSessionStorage', JSON.stringify(Date.now()));
      }
    }

      // Window storage event
 @HostListener('window:storage', ['$event'])
  public onStorage($event: any) {
    if ($event.key === 'getSessionStorage') {
      if (sessionStorage.length === 0) {
        // Ask other tabs for session storage
        localStorage.setItem('getSessionStorage', JSON.stringify(sessionStorage));
      }
      // Some tab asked for the sessionStorage -> send it
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
    } else if ( $event.key === 'sessionStorage' && sessionStorage.length === 0) {
       // sessionStorage is empty -> fill it
      const d = localStorage.getItem('sessionStorage');
      const data = JSON.parse(d as string);

      // tslint:disable-next-line:forin
      for (const key in data) {
        if(!excludedStorageKeys.includes(key)){
          sessionStorage.setItem(key, data[key]);
        }
      }
      localStorage.removeItem('sessionStorage');
    }
  }
}

