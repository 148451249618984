import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable({
  providedIn: 'root'
})
export class ThememodeService {

  private themeChanged$ = new ReplaySubject<void>();
  public themeChanged = this.themeChanged$.asObservable();

  private renderer!: Renderer2;
  private colorScheme!: string | null;
  // Define prefix for clearer and more readable class names in scss files
  private colorSchemePrefix = 'itopia-';

  constructor(rendererFactory: RendererFactory2) {
    // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  detectPrefersColorScheme(): void {
    // Detect if prefers-color-scheme is supported
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise set to light.
      this.colorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    } else {
      // If the browser doesn't support prefers-color-scheme, set it as default to dark
      this.colorScheme = 'dark';
    }
  }

  setColorScheme(scheme: string): void {
    this.colorScheme = scheme;
    // Save prefers-color-scheme to localStorage
    localStorage.setItem('prefers-color', scheme);
  }

  getColorScheme(): string {
    this.colorScheme = 'light';
    // Check if any prefers-color-scheme is stored in localStorage
    if (localStorage.getItem('prefers-color')) {
      // Save prefers-color-scheme from localStorage
      this.colorScheme = localStorage.getItem('prefers-color');
      return this.colorScheme === 'dark' ? this.colorScheme : 'light';
    } else {
      // If no prefers-color-scheme is stored in localStorage, try to detect OS default prefers-color-scheme
      this.detectPrefersColorScheme();
    }
    return this.colorScheme;
  }

  load(): void {
    this.getColorScheme();
    this.renderer.addClass(document.body, this.colorSchemePrefix + this.colorScheme);
  }

  update(scheme: string): void {
    this.setColorScheme(scheme);
    // Remove the old color-scheme class
    this.renderer.removeClass(document.body, this.colorSchemePrefix + (this.colorScheme === 'dark' ? 'light' : 'dark'));
    // Add the new / current color-scheme class
    this.renderer.addClass(document.body, this.colorSchemePrefix + scheme);
    this.setThemeChanged();
  }

  currentActive(): string | null {
    return this.colorScheme;
  }

  setThemeChanged(): void {
    this.themeChanged$.next();
  }
  applyStyleScheme(scheme: string): void {
    // Remove the old color-scheme class
    this.renderer.removeClass(document.body, this.colorSchemePrefix + (scheme === 'dark' ? 'light' : 'dark'));
    // Add the new / current color-scheme class
    this.renderer.addClass(document.body, this.colorSchemePrefix + scheme);
 }
}
