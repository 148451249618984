
export const iconsSvg = [
   {
       icon: 'user_awesome_dark',
       url: '/icons/user_awesome_dark.svg'
   },
   {
       icon: 'user_awesome_light',
        url: '/icons/user_awesome_light.svg'
   },
   {
    icon: 'sort_alphabetical_asc',
    url: '/favicons/sort-alphabetical-asc.svg'
},
{
    icon: 'sort_alphabetical_desc',
    url: '/favicons/sort-alphabetical-desc.svg'
},
{
    icon: 'sort_alphabetical',
    url: '/favicons/sort-alphabetical.svg'
},
{
    icon: 'sort_up',
    url: '/favicons/sort-up.svg'
},
{
    icon: 'sort_down',
    url: '/favicons/sort-down.svg'
},
 ];
