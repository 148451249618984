/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import {MatDialog} from '@angular/material/dialog';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
  ){ }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.token$.pipe(
        filter(token => token !== null), // Filtra hasta que el token no sea null
        take(1), // Toma el primer valor no null
        map(token => {
          if (token) {
            
            return true;
          } else {
            return this.router.createUrlTree(['/login']); // Redirigir a la página de login si no hay token
          }
        })
      );
    }
}
