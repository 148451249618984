export const resourcesImages = {
  'cloud': '/images/img-cloud.svg',
  'itopia-imagotype-dark': '/images/imagotype-labsDark.svg',
  'itopia-imagotype-light': '/images/imagotype-labsLight.svg',
  'cloud-itopia': '/images/cloud-itopia.svg',
  'itopia-labs': '/images/logo-itopia-labs.svg',
  'cloud-background': '/images/trial/background/footer.svg',
  'trial-bg-1': '/images/trial/background/background.svg',
  'trial-bg-2': '/images/trial/background/page-trial-finish.svg',
  'trial-img-finish': '/images/trial/images/img-trial-finish.svg',
  'windows-10': '/images/windows-10.png',
  'google-image': '/favicons/google.svg',
  'google-image-trial': '/favicons/google-trial.svg',
  'microsoft-image': '/favicons/microsoft.svg',
  'classlink-light': '/favicons/classlink-light.svg',
  'classlink-dark': '/favicons/classlink-dark.svg',
  'only-cloud': '/images/only-cloud.png',
  'wireframe-district-dark': '/images/wireframes/darkview-district.svg',
  'wireframe-school-dark': '/images/wireframes/darkview-school.svg',
  'wireframe-class-dark': '/images/wireframes/darkview-class.svg',
  'wireframe-single-class-dark': '/images/wireframes/darkview-single-class.svg',
  'wireframe-single-class-light': '/images/wireframes/lightview-single-class.svg',
  'wireframe-district-light': '/images/wireframes/lightview-district.svg',
  'wireframe-school-light': '/images/wireframes/lightview-school.svg',
  'wireframe-class-light': '/images/wireframes/lightview-class.svg',
  'imagotype-dark': '/images/imagotype-labsDark.svg',
  'imagotype-light': '/images/imagotype-labsLight.svg',
  'blank-profile': '/images/blank-profile-picture.webp',
  'offline-dark': '/images/connection/notconnect-dark.svg',
  'offline-light': '/images/connection/notconnect-light.svg',
  'bg-trial-login': '/images/trial/background/bg-login-trial.svg',
  'logo-itopia': '/images/logo-itopia.svg',
  'bg-circle-dark': '/images/trial/background/circle-dark.svg',
  'bg-circle-light': '/images/trial/background/circle-light.svg',
  'bg-arrows-figure-dark': '/images/trial/background/arrows-figure-dark.svg',
  'bg-arrows-figure-light': '/images/trial/background/arrows-figure-dark.svg',
  'google-ico': '/images/google-ico.png',
  'itopia-logo': '/images/itopia-logo.png',
  'rocket': '/images/rocket-dark.gif',
  'available-location': '/images/trial/images/available-location.png',
  'cloud-vertical-fullcolor': '/images/imagotype-vertical-fullcolor.svg',
  'cloud-vertical-white': '/images/imagotype-vertical-white.svg',
  'cloudapps-horizontal-white': '/images/imagotype-horizontal-white.svg',
  'only-appcloud': '/images/isotype-cloud-white.svg',
  'animation-card': '/images/trial/educator/card/animation-card.svg',
  'business-card': '/images/trial/educator/card/business-card.svg',
  'coding-card': '/images/trial/educator/card/coding-card.svg',
  'engineering-card': '/images/trial/educator/card/engineering-card.svg',
  'graphic-design-card': '/images/trial/educator/card/graphic-design-card.svg',
  'other-card': '/images/trial/educator/card/other-card.svg',
  'video-editing-card': '/images/trial/educator/card/video-editing-card.svg',
  'animation-icon': '/images/trial/educator/icon/animation-icon.svg',
  'business-icon': '/images/trial/educator/icon/business-icon.svg',
  'coding-icon': '/images/trial/educator/icon/coding-icon.svg',
  'engineering-icon': '/images/trial/educator/icon/engineering-icon.svg',
  'graphic-design-icon': '/images/trial/educator/icon/graphic-design-icon.svg',
  'other-icons': '/images/trial/educator/icon/other-icons.svg',
  'video-editing-icon': '/images/trial/educator/icon/video-editing-icon.svg',
  'adobe': '/images/trial/educator/curriculum/adobe.svg',
  'code': '/images/trial/educator/curriculum/code.svg',
  'gmetrix': '/images/trial/educator/curriculum/gmetrix.svg',
  'google-for-education': '/images/trial/educator/curriculum/google-for-education.svg',
  'pltw': '/images/trial/educator/curriculum/pltw.svg',
  'bg-coming-soon-light': '/images/trial/educator/bg-coming-soon.svg',
  'image-coming-soon': '/images/trial/educator/dark-coming-soon.svg',
  'smile-1': '/images/rating/smile-1.svg',
  'smile-2': '/images/rating/smile-2.svg',
  'smile-3': '/images/rating/smile-3.svg',
  'smile-4': '/images/rating/smile-4.svg',
  'smile-5': '/images/rating/smile-5.svg',
  'smile-1-sel': '/images/rating/selected/smile-1-sel.svg',
  'bg-demo':'/images/bg-demo.jpg',
  'active-session':'/images/info-code/active_session.svg',
  'cloud-apps-light':'/images/info-code/cloud_apps_light.svg',
  'cloud-apps-dark':'/images/info-code/cloud_apps_dark.svg',
  'clever': '/favicons/clever.svg'
}
