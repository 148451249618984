export const environment = {
  imagotype:false,
  production: false,
  authWithPopup: false,
  apiURL: 'labs-api.dev.labs.itopia.app',
  adminURL: 'https://portal.dev.labs.itopia.app',
  districtIdTrial: '9dw1c98KyhTG7NmzmhMx',
  schoolIdTrial: '3ODmOubUad262U4qg8Qp',
  userNameTrial: 'usertesttrial@itopia.com',
  passwordUserTrial: 'usertest2021!!!',
  speedTestUrl: 'https://k12-speedtest-us-central1-3hzy2j3q2a-uc.a.run.app/',
  hotJarId: 3432983,
  e2eTestToken:`eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJjcmVhdGVkX2F0IjoiMjAyMS0wOS0yN1QxMzozNzowMC4yNzZaIiwiZXhwIjoxNjY0Mjg1ODIwLCJlbWFpbCI6ImUyZUBpdG9waWEuY29tIn0.Blv2Sf3s58XjZBFWqUvlgw5k-KAyGzdZOSLV-kenCyc`,
  bucket: '',
  tenantId: '550f3bf9-4bd6-4f37-8ec0-e565039f2790',
  allowCountriesCode: ['US', 'CA', 'PR'],
  allowCountriesName: ['United States','Canada'],
  firebaseConfig : {"apiKey":"AIzaSyA_IH-qfDJkjsbD3P2cboP3JwISAe6N1ng","appId":"1:579538783315:web:2613dbb3263437a8fecaa6","authDomain":"labs-auth.dev.labs.itopia.app","databaseURL":"https://labs-dev-infra-x7-default-rtdb.firebaseio.com","measurementId":"","messagingSenderId":"579538783315","projectId":"labs-dev-infra-x7","storageBucket":"labs-dev-infra-x7.appspot.com"},
  classlinkClientId: 'c16638647530295fc0e96b3e3c8747bfb5a98dbc5398',
  cleverClientId: '2183d121433f1fd80932',
  cleverAuthUrl: 'https://clever.com/oauth/authorize',
  classlinkAuthUrl: 'https://launchpad.classlink.com/oauth2/v2/auth',
  exludeUsersTestConDefault: ['adelgado@itopia.com', 'afernandez@itopia.com','egonzalez@itopia.com','rdiaz@itopia.com', 'ycompanioni@itopia.us', 'mrosales@itopia.com'],
  streamerLostConnection: 6,
  appName: 'CloudApps Classroom',
  showTestDriveModal: 100,
  statusPath: '/api/status',
  checkStatusNetworkInterval: 5000,
  checkStatusNetworkRetry: 2,
  canvasUrl: 'https://canvas.instructure.com',
};
