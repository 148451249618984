/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {  Router } from '@angular/router';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable, ReplaySubject, timer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import '@firebase/auth';

import { AlertService } from './alert.service';
import { environment } from 'src/environments/environment';
import { firebaseProvider } from '../enums/firebaseProvider';
import { resourcesImages, URL_ASSETS } from '../consts';
import { AuthCookieService } from './cookies.service';
import { DistrictShort } from '../graphql';
import { Subscription } from 'rxjs/internal/Subscription';
import { AngularFirestore } from '@angular/fire/firestore';
import { CookieTkService } from './cookie.tk.service';

const userTrialEmail = environment.userNameTrial;
const networkError = 'Unable to connect to authentication provider, try again later or contact your provider.';

declare var sessionStorage: any;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any;
  currentProvider!: firebaseProvider;

  private loginFailed$ = new ReplaySubject<boolean>();
  public loginFailed = this.loginFailed$.asObservable();
  private authToken$ = new ReplaySubject<string>();
  public authToken = this.authToken$.asObservable();
  private authUser$ = new ReplaySubject<any>();
  public authUser = this.authUser$.asObservable();

  private authStateSubs!: Subscription;
  private idTokenSubs!: Subscription;
  loadingTkCookie!: boolean | undefined;
  token!: string;

  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public token$: Observable<string | null> = this.tokenSubject.asObservable();


  constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth, public router: Router, public ngZone: NgZone,
    private alertService: AlertService, private matDialog: MatDialog, private cookieTkService: CookieTkService,
    private authCookiesServ: AuthCookieService) {
    /* Saving user data in localstorage when
     logged in and setting up null when logged out */
    this.authStateSubs = this.afAuth.authState.subscribe((user: any) => {
      if (user?.email === userTrialEmail) { return; }
      if (user) {  
        if(user._lat){
          this.token = user._lat;
        }
        this.userData = user;
        this.tokenSubject.next(this.token);
        sessionStorage.setItem('user', JSON.stringify(this.userData));
        const fromDemo = this.authCookiesServ.getAuth('userId') && this.authCookiesServ.getAuth('userId') !== '';
        const isTrialRegister = this.router.url?.includes('register');
        const isStarted = this.router.url?.includes('started');
        const origin = sessionStorage.getItem('origin');
        this.setUserData(this.userData, Boolean(fromDemo) || isTrialRegister || isStarted || origin == 'admin');
      } else {
        sessionStorage.setItem('user', '');
      }
    });
  }

  getTkApi(token: string):Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadingTkCookie = true;
      this.cookieTkService.setCookie(token).subscribe(
        response => {
          console.log('Success setting cookie');
          this.loadingTkCookie = false;
          resolve(token);
        },
        error => {
          this.loadingTkCookie = false;
          resolve(false);
          console.error('Error setting cookie', error);
        }
      );  
    });
  }

  /* Sign up - Tested */
  public signUp = (email: string, password: string) => {
    this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.setUserData(result.user, true);
      }).catch((error) => {
        this.alertService.error(error.message, 'Authentication error');
      });
  }
  // Sign in with email/password
  public signIn = (email: string, password: string) => {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.setUserData(result.user);
        this.setLoginFailed(false);
      }).catch((error) => {
        this.alertService.error(error.message, 'Authentication error');
        this.setLoginFailed(true);
      });
  }

  getUserPhotoURL(user?: any): string {
    const photoURL = user?.photoURL ? user.photoURL :
    user?.providerData?.length > 0 && user.providerData[0].photoURL ? user.providerData[0].photoURL:
    URL_ASSETS + resourcesImages['blank-profile'];
    return photoURL;
  }

  /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user: any, signup?: boolean): void {
    const comesFromLti = sessionStorage.getItem('origin') === 'lti';
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: this.getUserPhotoURL(user),
      emailVerified: user.emailVerified
    };

    this.idTokenSubs = this.afAuth.idToken.subscribe(async (token) => {
      if(token){
        this.token = token;
        this.resolveUserCallback(userData, token);
      }
    });
   
  }

  setSessionActive(session: boolean): void {
    sessionStorage.setItem('sessionActive', session);
  }

  getSessionActive(): boolean {
    return sessionStorage.getItem('sessionActive');
  }

  setUserDataPromise(user: any, tk?:string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: this.getUserPhotoURL(user),
        emailVerified: user.emailVerified
      };
      this.idTokenSubs = this.afAuth.idToken.subscribe(async (token) => {
         const tkAux = token || tk;
         this.getTkApi(tkAux as string).then((tk)=>{
          if(tk){
            this.resolveUserCallback(userData, tkAux as string);
            resolve(userData);
          }            
         });
       });
    });
  }

  private resolveUserCallback(userData: any, token:string| null): void {
    if(token){
      this.token = token; 
    };  
    this.setToken(token);
    sessionStorage.setItem('user', JSON.stringify(userData));
    this.setLoginFailed(false);
    this.setUser(userData);
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(JSON.stringify(sessionStorage.getItem('user')));
    return ( user && user !== 'null' && user.emailVerified !== false) ? true : false;
  }

  get isAnonymous(): boolean {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return ( user && user.email !== 'null') ? true : false;
  }
  // Sign in with Google
  googleAuth = (signup?: boolean) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.authLogin(provider, signup);
  }

  authLoginPromise = (isMicrosoft?: boolean): Promise<boolean> => {
    return new Promise((resolve) => {
      const provider = isMicrosoft ?  new firebase.auth.OAuthProvider(firebaseProvider.Microsoft): new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account'
        });
        return this.afAuth.signInWithPopup(provider)
          .then((result) => {
            this.setUserDataPromise(result.user).then((value: boolean) => {
              resolve(value);
            });
          }).catch((error) => {
            resolve(false);
            this.showErrorMessage(error);
        });
    });
  }

  classLinkAuth = () => {
    const provider = new firebase.auth.OAuthProvider(firebaseProvider.ClassLink);
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    return this.oauthLogin(provider, true);
  }

  // Sign in with Microsoft
  microsoftAuth = (signup?: boolean) => {
    const provider = new firebase.auth.OAuthProvider(firebaseProvider.Microsoft);
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    return this.oauthLogin(provider, signup);
  }

  // Auth logic to run auth providers
  authLogin = (provider: any, signup?: boolean) => {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user);
      }).catch((error) => {
        this.showErrorMessage(error);
      });
  }

  signInAnonymously():Promise<any> {
    return this.afAuth.signInAnonymously();
  }

  signInWithRedirect = (provider:any): Promise<void> => {
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.afAuth.signInWithRedirect(provider);
  }

  showErrorMessage(error:any): void {
    const isNetworkError = error?.message.includes('network error');
    const msg = isNetworkError ? networkError : error?.message;
    this.alertService.error(msg, 'Authentication error');
    this.setLoginFailed(true);
  }

  oauthLogin = (provider: any, signup?: boolean) => {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user);
      }).catch((error) => {
        if (error.email && error.credential && error.code === 'auth/account-exists-with-different-credential') {
          this.fetchSignInMethodsForEmail(error, signup);
        } else if (error.message.includes("error_description=AADSTS65004")) {
          this.setLoginFailed(true);
        } else {
           this.showErrorMessage(error);
        }
      });
  }
  // Firebase SignInWithPopup
  oAuthProvider = (provider: any) => {
    return this.afAuth.signInWithPopup(provider).catch((error) => {
      return error;
    });
  }

  fetchSignInMethodsForEmail = (error: any, signup?: boolean): any => {
    return firebase.auth().fetchSignInMethodsForEmail(error.email)
      .then(providers => {
        switch (providers[0]) {
          case 'password':
            break;
          default:
            const authProvider = new firebase.auth.OAuthProvider(providers[0]);
            this.oAuthProvider(authProvider).then((result) => {
              if (result && result?.code === 'auth/popup-blocked') {
                this.alertService.warn(result.message, 'Authentication warning');
                this.setLoginFailed(true);
                return;
              }
              if (result.user) {
                result.user.linkWithCredential(error.credential).then((usercred: any) => {
                  this.setUserData(result.user);
                }, () => {
                  this.alertService.error(error, 'Authentication error');
                  this.setLoginFailed(true);
                });
              } else {
                this.alertService.error(result.message, 'Authentication error');
                this.setLoginFailed(true);
                return;
              }
            }, () => {
              this.alertService.error(error, 'Authentication error');
              this.setLoginFailed(true);
            });
            break;
        }
      }).catch((error) => {
        this.alertService.error(error, 'Authentication error');
        this.setLoginFailed(true);
      });
  }

  authLoginTrial(isGoogle: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      const provider = isGoogle ? new firebase.auth.GoogleAuthProvider() : new firebase.auth.OAuthProvider(firebaseProvider.Microsoft);
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      this.afAuth.signInWithPopup(provider)
        .then((result) => {
          const user = result.user;
          if (!user || (user && user?.email?.includes('gmail.com'))) {
            this.afAuth.signOut().then(() => {
              this.alertService.error('Authentication by the selected email is not allowed. The trial requires a domain that has a workspace configured.', 'Invalid domain');
            });
            resolve(false);
          } else {
            this.setUserDataPromise(result.user).then((user) => {
              resolve(user);
            });
          }
        }).catch((error) => {
          this.showErrorMessage(error);
          resolve(false);
        });

    });
  }

  // Sign out
  signOut = (demo?: boolean) => {
    return this.afAuth.signOut().then(() => {
      this.signOutCallback();
      if(!demo) {
        window.location.replace('/login');  
      }
    });
  }
    // Sign out
    signOutTrial = () => {
      return this.afAuth.signOut().then(() => {
        this.signOutCallback();
        void this.router.navigate(['/trial']).then(()=>{
          window.location.reload();
        });
      });
    }

     // Sign out
     signOutRegister = (redirect: string) => {
      return this.afAuth.signOut().then(() => {
        this.signOutCallback();
        window.location.replace(redirect);
      });
    }


  private signOutCallback(): void {
    sessionStorage.clear();
    this.authCookiesServ.deleteAuth('user');
    this.matDialog.closeAll();
    if(this.authStateSubs){
      this.authStateSubs.unsubscribe();
    }
    if(this.idTokenSubs){
      this.idTokenSubs.unsubscribe();
    }
  }

  authWithCustomTk(tk: string, redirectUrl: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithCustomToken(tk)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        if (!user) {
          this.alertService.error('The specified token is not valid.', 'Invalid token');
          if(!redirectUrl) {
            this.router.navigate(['/login']);
          }
          resolve(false);
        } else {
          this.setUserDataPromise(user).then((user) => {
            resolve(user);
            if(redirectUrl && redirectUrl.indexOf('dashboard') > -1 ) {
              console.log('authWithCustomTk redirectUrl', redirectUrl);
              this.router.navigate([redirectUrl]);
            }
          });
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        this.alertService.error(errorMessage, 'Invalid token');
        if(!redirectUrl || redirectUrl !== 'dashboard') {
          this.router.navigate(['/login']);
        }
        resolve(false);
      });
    });
  }

  async renewToken(): Promise<boolean> {
    try {
      const user = await this.afAuth.currentUser;
      if (user) {
        const tk = await user.getIdToken(true);
        return this.setUserDataPromise(user,tk);
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error renewing token:", error);
      return false;
    }
  }

  signInUserAndPassword(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          if (user !== null) {
            user.getIdToken(true).then(id => {
              resolve({
                token: id,
                user: user
              });
            });
          }
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  setLoginFailed(loginFailed: boolean): void {
    this.loginFailed$.next(loginFailed);
  }
  setToken(token: string | null): void {
    this.authToken$.next(token?.toString());
  }
  setUser(user: any): void {
    this.authUser$.next(user);
  }
  getUserFromStorage(): any {
    const u = sessionStorage.getItem('user');
    return u ? JSON.parse(sessionStorage.getItem('user') as string): {};
  }

  getProviderFromStorage(): any {
    const provider = sessionStorage.getItem('currentProvider');
    return provider;
  }

  getCurrentDistrictFromStorage(): any {
    const d = sessionStorage.getItem('UserDistrictSelected');
    return d ? JSON.parse(sessionStorage.getItem('UserDistrictSelected') as string): {};
  }

  setLastDistrict(districtId: string): void {
    sessionStorage.setItem('lastDistrict', districtId);
  }

  setDistrictSchool(districtId: string): void {
    sessionStorage.setItem('districtSchool', districtId);
  }

  getLastDistrictId(): string {
    return sessionStorage.getItem('lastDistrict') as string;
  }

  getLastDistrict(): DistrictShort | undefined {
    const district = sessionStorage.getItem('lastDistrict');
    const districts = this.getDistricts();
    return districts.find((dist) => dist.districtId === district);
  }
  getSchoolbyLastDistrict(): any {
    const schoolId = this.getLastSchool();
    const lastDistrict = this.getLastDistrict();
    return lastDistrict?.schools?.find((sch) => sch?.schoolId === schoolId);
  }

  getDistricts(): DistrictShort [] {
    const districts = sessionStorage.getItem('districts');
    if (districts) {
      return JSON.parse(districts);
    } else {
      return [];
    }
  }
  getLastSchool(): string | null {
    return sessionStorage.getItem('lastSchool');
  }

    // Sign in with popup mechanism
  authPopUp = (provider: any) => {
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.authLoginPromisePopUp(provider);
  } 

  authLoginPromisePopUp = (provider: any): Promise<boolean> => {
    return new Promise((resolve) => {     
        provider.setCustomParameters({
          prompt: 'select_account'
        });
        return this.afAuth.signInWithPopup(provider)
          .then((result) => {
            this.setUserDataPromise(result.user).then((value: boolean) => {
              resolve(value);
            });
          }).catch((error) => {
            resolve(false);
            this.showErrorMessage(error);
        });
    });
  }
}
