import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Component, OnInit, Inject } from '@angular/core';

import { AlertType } from '../../enums';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent  {
  alertType!: AlertType | string;
  link!: string;
  linkDescription!: string;
  description!: string;
  title!: string;

  constructor(private snackBar: MatSnackBar,  @Inject(MAT_SNACK_BAR_DATA) public data: any) {
       this.alertType = data.alertType as AlertType;
       this.title = data.title;
       this.link = data.link?.link;
       this.linkDescription = data.link?.linkDescription;
       this.description = data.description;

   }
  close(): void {
    this.snackBar.dismiss();
  }
}
